import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse , { domToReact } from "html-react-parser"
import {Adsense} from '../components/adsense'
import Ezoic from '../components/ezoic'

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import chicken_escape_banner_image from '../images/chicken_escape_banner.jpg'
import dragontimes_banner from '../images/dragontimes_banner.jpg'

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Like } from 'react-facebook';

import RelatedPosts from "../components/related_posts";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const tagList = post.tags.nodes.map((tag) =>    
      <span key={tag.slug}>
        <a  href={`/tag/${tag.slug}`}>
          {tag.name}<small className="text-muted pl-1 ml-0">({tag.count})</small>
        </a> /     
      </span>   
    );

  const parseContent = (content) => {
    let cnt=0
    const options = {
      replace: (domNode) => {        
        if(domNode.type === 'tag' && domNode.name === 'p') {
          if (cnt == 1) {            
            cnt++
            return (
              <div className="changed">
                <p>{domToReact(domNode.children)}</p>
                <Adsense slot="8601581969" layout="in-article" format="fluid" />                
              </div>
            )
          }
          if (cnt == 3) {            
            cnt++
            return (
              <div className="changed">
                <p>{domToReact(domNode.children)}</p>
                <Adsense slot="2064366841" layout="in-article" format="fluid" />                
              </div>
            )
          }          
          cnt++
        }      
        return domToReact(domNode)
      }
    };
    let components =  parse(content, options)    
    return components
  } 
  
  return (
    <Layout>
      <SEO title={post.title} description={post.excerpt} image={post.featuredImage} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{parse(post.title)}</h1>

          <p>{post.date}</p>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              style={{ marginBottom: 50 }}
            />
          )}
          {!featuredImage?.fluid && post.featuredImage && post.featuredImage.node && (
          <img src={post.featuredImage.node.localFile.publicURL} />
          )}

        </header>

        <div style={{height:'35px'}}>
          <Like href={`https://mathcurious.com/${post.uri}`} colorScheme="light"  share layout="button_count" size="large" />        
        </div>

        <p></p>
        

        {!!post.content && (
          <section itemProp="articleBody">{parseContent(post.content)}</section>
        )}

        
        <div className="row" style={{paddingBottom:'30px'}}>
        {post.resourceLinks && post.resourceLinks.freeUrl && ( <div className="col"><a className="btn btn-success" href={post.resourceLinks.freeUrl}>Free Version</a></div> )}
        {post.resourceLinks && post.resourceLinks.premiumUrl && ( <div className="col"><a className="btn btn-success" href={post.resourceLinks.premiumUrl}>Premium Version</a></div>) }
        </div>
        
        


        <hr />
        <footer style={{paddingBottom: '20px'}}>
          {tagList}
        </footer>
        <hr/>

        
         
          
        <RelatedPosts posts={post.related_posts} customPosts={post.postsToPostsConnection}/>
        

       

          <hr/>
          <div className="row">
            <h4>Check out our best selling card games now available at amazon.com and amazon.ca</h4>

            <div className="card" style={{maxWidth: '430px', margin:'20px'}}>                
              <img src={chicken_escape_banner_image} />            
              <div className="card-body">
                <h5 className="card-title"><a  href="/2020/05/23/chicken-escape/">Chicken Escape</a> </h5>
                <p className="card-text font-size-sm">A multiplayer card game that makes mental math practice fun! Chicken Escape is a fast-paced multiplayer card game. While playing… </p>
                <a href="/2020/05/23/chicken-escape/" className="btn btn-sm btn-success">Read More</a>
              </div>
            </div>              

            <div className="card" style={{maxWidth: '430px', margin:'20px'}}>                
              <img src={dragontimes_banner} />            
              <div className="card-body">
                <h5 className="card-title"><a  href="/2020/05/13/dragon-times-a-math-adventure-card-game/">Dragon Times – A math Adventure card game</a> </h5>
                <p className="card-text font-size-sm">Dragon Times is an educational fantasy card game that aims to motivate children to practice multiplication and division facts while…</p>
                <a href="/2020/05/13/dragon-times-a-math-adventure-card-game/" className="btn btn-sm btn-success">Read More</a>
              </div>
            </div>              
      

          </div>
      </article>      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      resourceLinks {
        freeUrl
        premiumUrl
      }
      date(formatString: "MMMM DD, YYYY")
      uri
      tags {
        nodes {
          slug
          name
          count
        }
      }
      related_posts {
        nodes {
          guid
          uri
          id
          title
          featuredImage {
            node {
              altText          
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      postsToPostsConnection {
        nodes {
          guid
          uri
          id
          title
          featuredImage {
            node {
              altText          
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText          
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
