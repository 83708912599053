/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {Adsense} from "./adsense"

const RelatedPosts = (props) => {
    let allPosts = []
    let cnt = 0
    if (props.customPosts.nodes) {
        cnt = props.customPosts.nodes.length
    }
    props.customPosts.nodes.forEach((node) => {
        allPosts.push(node)
    })
    if (props.posts.nodes) {
        props.posts.nodes.forEach((node) => {
            if(cnt < 6) {
                allPosts.push(node)
                cnt++
            } 
        })
    }

    if (allPosts.length == 0) return ''
    cnt =0
  const relatedPosts = allPosts.map((node) => {   
    const image = {
      fluid: node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
      alt: node.featuredImage?.node?.alt || ``,
    }
    cnt++
    if (cnt == 3) {
      return (      
        <div key={node.id} className="card related-card" style={{maxWidth: '430px', margin:'20px'}}>        
          <Adsense slot="4596448843" layout="in-article" format="fluid" layoutKey="-62+ca+8-3b+jw" />
        </div>              
      )
    } else {
      return (      
        <div key={node.id} className="card related-card" style={{maxWidth: '430px', margin:'20px'}}>        
          <a href={node.uri} > {image?.fluid && (
              <Image
                className="card-img-top"              
                fluid={image.fluid}
                alt={image.alt}              
              />
            )}
            {!image?.fluid && node.featuredImage?.node && (
            <img src={node.featuredImage?.node.localFile.publicURL} />
            )}
            </a>
          <div className="card-body">
            <h5 className="card-title"><a  href={node.uri}>{node.title}</a> </h5>
            <p className="card-text font-size-sm"></p>
            <a href={node.uri} className="btn btn-sm btn-success">Read More</a>
          </div>
        </div>              
      )
    }
  });


  return (
    <div className="row" >
        <h3 >You might also like</h3>
        <div className="row" >{relatedPosts}</div>      
    </div>
  )
}

export default RelatedPosts
